import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React from "react";

const WithHomeBackgroundImage = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "home_bg.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const backgroundFluidImageStack = [
    data.desktop.childImageSharp.fluid,
    `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))`,
  ].reverse();

  return (
    <BackgroundImage fluid={backgroundFluidImageStack} className="bg-image">
      <div className="vh-100">{children}</div>
    </BackgroundImage>
  );
};

export default WithHomeBackgroundImage;
