import React, { Fragment } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../components/style.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Seo from "../components/Seo";
import WithHomeBackgroundImage from "../components/WithHomeBackgroundImage";
import Button from "react-bootstrap/Button";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import ContactForm from "../components/ContactForm";
import { FiMapPin, FiPhone, FiMail } from "react-icons/fi";

export const caiLogoQuery = graphql`
  query {
    file(relativePath: { eq: "cai_logo.jpg" }) {
      childImageSharp {
        fixed(width: 271, height: 117) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  return (
    <Fragment>
      <WithHomeBackgroundImage>
        <Seo title="Home" />
        <Container className="h-100">
          <Row className="pt-5 mb-2">
            <Col>
              <h1 className="display-1 d-none d-md-block">
                Pemaquid
                <br />
                Reserves
                <br />
                Planner
              </h1>
              <h1 className="display-4 d-md-none">
                Pemaquid
                <br />
                Reserves
                <br />
                Planner
              </h1>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col>
              <h2>
                <strong>Guiding HOA Reserves Planning for Success</strong>
              </h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="lead">
                Sign up today and start your reserves planning journey
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <div className="d-grid">
                <Button
                  variant="secondary"
                  size="lg"
                  href="#learn-more"
                >
                  Learn More
                </Button>
              </div>
            </Col>
            <Col md={2} className="mt-md-0 mt-5">
              <div className="d-grid">
                <Button
                  variant="success"
                  size="lg"
                  href={`${process.env.GATSBY_APP_URL}/signup`}
                >
                  Sign Up
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col></Col>
          </Row>
        </Container>
      </WithHomeBackgroundImage>
      <div id="learn-more" className="learn-more">
        <Container>
          <Row className="mb-3">
            <Col className="text-center">
              <p className="h2 text-body display-4">Want to learn more?</p>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="text-center text-secondary">
              <p>
                Watch this brief introductory video about the capabilities of
                this application
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex justify-content-center align-items-center">
                <iframe
                  title="Introduction"
                  width="712"
                  height="400"
                  src="https://www.youtube-nocookie.com/embed/io2sZTrGKGk"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="pre-footer">
        <Container>
          <Row>
            <Col className="d-flex align-items-center justify-content-center">
              <span className="h1 font-weight-lighter">
                Ready to get started? Sign up now
              </span>
              <Button
                variant="success"
                className="ms-4"
                size="lg"
                href={`${process.env.GATSBY_APP_URL}/signup`}
              >
                Sign up
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer">
        <Container>
          <Row>
            <Col md={8}>
              <Row className="mb-4">
                <Col className="d-flex justify-content-center">
                  <p className="h2 font-weight-light">Contact Us</p>
                </Col>
              </Row>
              <Row>
                <Col md={5}>
                  <Row className="mb-4">
                    <Col className="d-flex align-items-center">
                      <FiMapPin />
                      <p className="mb-0 ms-3">
                        PO Box 892
                        <br />
                        Kennebunk, ME 04043
                      </p>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="d-flex align-items-center">
                      <FiMail />
                      <p className="mb-0 ms-3">info@pemaquidreserves.com</p>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="d-flex align-items-center">
                      <FiPhone />
                      <p className="mb-0 ms-3">(207) 232-8859</p>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <ContactForm
                    apiUrl={process.env.GATSBY_API_URL}
                    siteKeyV2={process.env.GATSBY_GOOGLE_RECAPTCHA_V2_SITE_KEY}
                    siteKeyV3={process.env.GATSBY_GOOGLE_RECAPTCHA_V3_SITE_KEY}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="d-flex flex-column justify-content-end ps-5 mt-md-0 mt-5">
              <ul className="list-unstyled mb-5">
                <li className="mb-4">
                  <a
                    className="text-decoration-none"
                    href={`${process.env.GATSBY_APP_URL}/signin`}
                  >
                    Sign in
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    className="text-decoration-none"
                    href={`${process.env.GATSBY_APP_URL}/faq`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    FAQ
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    className="text-decoration-none"
                    href={`${process.env.GATSBY_APP_URL}/terms`}
                  >
                    Terms and Conditions
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    className="text-decoration-none"
                    href={`${process.env.GATSBY_APP_URL}/privacy`}
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
              <a
                href="https://www.caine.org"
                className="text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="h4 font-weight-lighter">Proud Member</p>
                <Img fixed={data.file.childImageSharp.fixed} />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default IndexPage;
