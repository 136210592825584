import { useFormik } from "formik";
import { object, string } from "yup";
import axios from "axios";
import Form from "react-bootstrap/Form";
import {
  EReCaptchaV2Size,
  EReCaptchaV2Theme,
  ReCaptchaProvider,
  ReCaptchaV2,
  ReCaptchaV3,
} from "react-recaptcha-x";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import React, { Fragment } from "react";
import { FaRegCheckCircle } from "react-icons/fa";

const ContactForm = ({ apiUrl, siteKeyV2, siteKeyV3 }) => {
  const isBrowser = () => typeof window !== "undefined";
  const formik = useFormik({
    initialStatus: "v3",
    initialValues: {
      name: "",
      email: "",
      message: "",
      token: "",
    },
    validationSchema: object({
      name: string().required(),
      email: string().email().required(),
      message: string().required(),
      token: string().required(),
    }),
    onSubmit: async (values, actions) => {
      let result = await axios.post(`${apiUrl}/contact`, {
        "g-recaptcha-response": values.token,
        name: values.name,
        email: values.email,
        message: values.message,
      });
      actions.setSubmitting(false);

      if (result.data.success) {
        formik.setStatus("success");
        return;
      }

      if (result.data.errors) {
        formik.setErrors({ email: result.data.errors[0] });
        return;
      }

      if (formik.status === "v3") {
        formik.setStatus("v2");
        return;
      }
      formik.setStatus("robot");
    },
  });

  const v2Callback = async (t) => {
    if (typeof t === "string") {
      formik.setFieldValue("token", t);
      formik.handleSubmit();
    } else if (typeof t === "boolean" && !t) {
      window.grecaptcha.reset();
    } else if (t instanceof Error) {
      formik.setStatus("networkConnectivity");
    }
  };
  const v3Callback = (t, refreshToken) => {
    if (typeof t === "string") {
      formik.setFieldValue("token", t);
    }
  };

  if (!isBrowser()) return <></>;
  if (isBrowser())
    return (
      <>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <ReCaptchaProvider
            siteKeyV2={siteKeyV2}
            siteKeyV3={siteKeyV3}
            langCode="en"
            hideV3Badge={false}
          >
            {formik.status === "v3" && (
              <Fragment>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Name"
                    isInvalid={formik.touched.name && formik.errors.name}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Email Address"
                    isInvalid={formik.touched.email && formik.errors.email}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    rows={4}
                    name="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Message"
                    isInvalid={formik.touched.message && formik.errors.message}
                  />
                </Form.Group>
                <div className="d-grid">
                  <Button
                    disable={formik.isSubmitting.toString()}
                    variant="primary"
                    type="submit"
                  >
                    {formik.isSubmitting ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Send Message"
                    )}
                  </Button>
                </div>

                <ReCaptchaV3 action="contact_us" callback={v3Callback} />
              </Fragment>
            )}
            <ReCaptchaV2
              callback={v2Callback}
              theme={EReCaptchaV2Theme.Light}
              size={EReCaptchaV2Size.Normal}
              tabindex={0}
              className={formik.status === "v2" ? "" : "d-none"}
            />
          </ReCaptchaProvider>
        </Form>

        {formik.status === "success" && (
          <div>
            <FaRegCheckCircle size="1.5em" color="#51a150" />
            <span className="ms-2">
              <u>
                <strong>Thank you for reaching out.</strong>
              </u>
              &nbsp;We will be in touch!
            </span>
          </div>
        )}

        {formik.status === "robot" && (
          <span>Error processing request. Are you a robot?</span>
        )}

        {formik.status === "robot" && (
          <span>Error processing request. Are you a robot?</span>
        )}

        {formik.status === "networkConnectivity" && (
          <span>
            There seems to be a network connectivity issue. Please refresh and
            try again.
          </span>
        )}
      </>
    );
};

export default ContactForm;
